@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';
@import './styles/index.scss';
.pagination {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #0F8BFD;
    background-color: rgba(255, 255, 255, 0.05);
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.selected .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

